<template>
  <div>
    <v-autocomplete
      :disabled="disabled"
      class="users-autocomplete"
      variant="outlined"
      :items="availableUsers"
      :loading="isLoadingEmails"
      item-value="value"
      item-title="text"
      multiple
      :placeholder="label"
      :search="search"
      prepend-inner-icon="mdi-magnify"
      :model-value="select"
      data-testid="users-autocomplete"
      @update:search="onSearch"
      @update:model-value="onChange"
    >
      <template #selection="{ index }">
        <span data-testid="selected-text" class="mr-1" v-if="index === 0">{{ select.length }} selected</span>
      </template>

      <template #no-data>
        <div class="pa-3">
          <v-progress-linear indeterminate color="primary" v-if="isLoadingEmails" />
          <template v-else>
            {{ !search || search?.length < 3 ? 'Type at least 3 characters to search' : 'No users found' }}
          </template>
        </div>
      </template>
    </v-autocomplete>
    <LimitedList :key="overflowLimit" wrap :items="select" :limit="overflowLimit">
      <template #no-data></template>
      <template #item="props">
        <v-chip
          data-testid="selected-item"
          size="small"
          variant="outlined"
          :closable="!disabled && (props.item !== staticItem || !disableRemovingStaticItem)"
          close-icon="mdi-close"
          class="text-on-surface-variant"
          @click:close="onItemRemove(props.item)"
        >
          {{ props.item }}
        </v-chip>
      </template>
      <template #more>
        <v-btn data-testid="show-more" variant="text" size="small" class="text-primary" @click="onMoreClick"> + more </v-btn>
      </template>
    </LimitedList>
  </div>
</template>

<script lang="ts">
import { PropType, computed, onMounted, ref, watch } from 'vue';
import { findUsers } from '@console/core/service';
import { LimitedList } from '@console/ui/LimitedList';
import { handleAsync } from '@console/utils';

export default {
  name: 'UsersAutocomplete',
  components: { LimitedList },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    excludedEmails: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    label: {
      type: String,
      default: 'Type to search for users',
    },
    staticItem: {
      type: String,
    },
    modelValue: {
      type: Array as PropType<string[]>,
    },
    maxSelected: {
      type: Number,
      default: -1,
    },
  },
  emits: ['update:model-value'],

  setup(props, { emit }) {
    const search = ref<string>('');
    const select = ref<string[]>(props.modelValue || []);
    const users = ref<string[]>([]);
    const disableRemovingStaticItem = ref(false);

    const excludedEmailsSet = computed<Set<string>>(() => {
      return new Set(props.excludedEmails);
    });

    const availableUsers = computed(() => {
      return users.value.reduce<Array<{ text: string; value: string; disabled: boolean }>>((acc, item) => {
        if (!excludedEmailsSet.value.has(item)) {
          acc.push({ text: item, value: item, disabled: item === props.staticItem && disableRemovingStaticItem.value });
        }
        return acc;
      }, []);
    });

    const onChange = (value: string[]) => {
      if (props.maxSelected > 0 && value.length > props.maxSelected) {
        return;
      }

      select.value = value;

      // when removing the static item using the backspace it's added back to the list
      if (disableRemovingStaticItem.value && !select.value.includes(props.staticItem)) {
        select.value.push(props.staticItem);
      }

      onSearch('');
      emit('update:model-value', select.value);
    };

    const overflowLimit = ref(3);

    const onMoreClick = () => {
      overflowLimit.value = select.value.length;
    };

    const onItemRemove = (item: string) => {
      if (item !== props.staticItem || !disableRemovingStaticItem.value) {
        select.value.splice(select.value.indexOf(item), 1);
        emit('update:model-value', select.value);
      }
    };

    onMounted(() => {
      disableRemovingStaticItem.value = props.modelValue?.includes(props.staticItem) ?? false;
    });

    const isLoadingEmails = ref(false);
    const onSearch = async (value: string) => {
      search.value = value;

      if (value?.length >= 3) {
        isLoadingEmails.value = true;
        const [, response] = await handleAsync(findUsers(search.value));
        if (response) {
          users.value = [...select.value, ...response.matches];
        }
        isLoadingEmails.value = false;
      } else {
        users.value = [...select.value];
      }
    };

    watch(
      () => props.modelValue,
      value => {
        select.value = value;
      }
    );

    return {
      onChange,
      onItemRemove,
      onMoreClick,
      onSearch,
      isLoadingEmails,
      overflowLimit,
      search,
      select,
      availableUsers,
      disableRemovingStaticItem,
    };
  },
};
</script>

<style scoped lang="scss">
#console .users-autocomplete:deep(.v-input__prepend-inner) {
  margin-right: 8px;
}
</style>

import { JIRA_REGEX } from '@console/constants';

export type FormValidationResult = string | boolean;

/**
 * Email validation Regexp
 * @type RegExp
 * @see http://emailregex.com/
 */
export const EMAIL_RE =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const IP_RE =
  /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
export const NUMBER_RE = /^(?=.)([+-]?([0-9]*)(\.([0-9]+))?)$/;
export const SESSION_LONG_RE = /[a-fA-F0-9]{11}x[a-fA-F0-9]{1,8}/;

const notRequired = (val: any) => [null, undefined, ''].includes(val);

/**
 * Forces input to have value
 *
 *
 * @param {*} value
 * @returns {FormValidationResult}
 */
export function isRequired(value: any): FormValidationResult {
  return (value !== null && value !== undefined && value !== '') || 'Field is required';
}

/**
 * Forces input to be Number
 *
 *
 * @param {*} value
 * @returns {FormValidationResult}
 */
export function isNumber(value?: any): FormValidationResult {
  return (!notRequired(value) && Number.isNaN(value) === false) || 'Number is invalid';
}

/**
 * Forces input to be valid email
 *
 *
 * @param {string} email
 * @returns {FormValidationResult}
 */
export function isEmail(email: string): FormValidationResult {
  return notRequired(email) || EMAIL_RE.test(email) || 'Email is invalid';
}

/**
 * Forces input to be valid IP address
 *
 *
 * @param {string} ip
 * @returns {FormValidationResult}
 */
export function isIP(ip: string): FormValidationResult {
  return notRequired(ip) || IP_RE.test(ip) || 'IP address is invalid';
}

/**
 * Forces iinput to be valid JSON. Uses JSON.parse()
 *
 *
 * @param {string} json
 * @returns {FormValidationResult}
 */
export function isJSON(json: string): FormValidationResult {
  try {
    if (json.trim() === '') {
      return true;
    }
    JSON.parse(json);
  } catch {
    return 'JSON is invalid';
  }
  return true;
}

/**
 * Forces input to be JIRA id
 *
 * @param {string} value
 * @returns {FormValidationResult}
 */
export function isJiraID(value) {
  return notRequired(value) || new RegExp(JIRA_REGEX).test(value) || 'Jira ID is invalid';
}

export function isSessionLong(value) {
  return notRequired(value) || new RegExp(SESSION_LONG_RE).test(value) || 'session_long is invalid';
}

export function isRequiredList(value) {
  const length = Array.isArray(value) ? value.length : Object.keys(value).length;

  return length > 0 ? true : 'Value is required';
}

export function isValidEmailsList(value) {
  if (Array.isArray(value) && value.every(v => new RegExp(EMAIL_RE).test(v))) {
    return true;
  }

  return 'Enter valid emails';
}

export function isFloat(value) {
  return /^[-+]?\d*\.?\d*$/gi.test(value) || 'Value is not a valid number';
}

export function isInteger(value) {
  return /^[-]?[0-9]+$/.test(value) || 'Value must be a valid integer';
}

export function isPositive(value) {
  return /^\d*\.?\d*$/gi.test(value) || 'Value must be a positive number';
}

export function isMaxLength(limit) {
  return value => value?.length <= limit || `Value must have at most ${limit} characters`;
}

export function isMinLength(limit) {
  return value => value?.length >= limit || `Value must have at least ${limit} characters`;
}

export function isMaxValue(limit: number) {
  return value => value <= limit || `Value must be less than or equal to ${limit}`;
}

export function isMinValue(limit: number) {
  return value => value >= limit || `Value must be greater than or equal to ${limit}`;
}

export function isLessThan(limit: number) {
  return value => value < limit || `Value must be less than ${limit}`;
}

export function isGreaterThan(limit: number) {
  return value => value > limit || `Value must be greater than ${limit}`;
}

export function isDivisibleBy(divisor: number, tolerance = 1e-9) {
  return value => {
    const remainder = Math.abs(value % divisor);
    if (remainder < tolerance || Math.abs(divisor - remainder) < tolerance) {
      return true;
    }
    return `Value must be divisible by ${divisor}`;
  };
}

/**
 * Forces input to be valid URL address
 *
 * @param {string} value
 * @returns {FormValidationResult}
 */
export function isURLValid(value: string): FormValidationResult {
  if (!value) {
    return true;
  }
  let url;
  const err = 'Invalid URL provided';

  try {
    url = new URL(value);
  } catch (_) {
    return err;
  }

  const pattern = new RegExp(
    '^([a-zA-Z]+:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', // fragment locator
    'i'
  );

  return ((url.protocol === 'http:' || url.protocol === 'https:') && pattern.test(value)) || err;
}

export function hasMeaningfulText(value: string): FormValidationResult {
  // contains a word with at least 3 letters
  const regex = /\b[a-zA-Z]{3,}\b/;
  return regex.test(value) || 'The field must contain some meaningful text';
}

import { computed, ref } from 'vue';
import { CONSOLE_ENV } from '@console/constants';
import { CofferAPI, ReleasesAPI } from '@console/core/http';
import menuConfigDev from '@console/core/menuConfig.dev.json';
import menuConfig from '@console/core/menuConfig.json';
import { ConsoleApp, ConsoleProduct } from '@console/types';
import { type DataTableResponse } from '@console/ui/DataTable';
import { type ReleaseNote } from '@console/ui/ServiceAboutPage';
import { handleAsync } from '@console/utils';
import { useLDConfig } from './useConfig';
import { useUser } from './useUser';

export type ProductsDefinition = {
  helperItems: ConsoleProduct[];
  products: ConsoleProduct[];
  additionalItems: Array<ConsoleProduct[]>;
};

function isProductEnabledForEnv(p: ConsoleProduct) {
  return p ? !p.disabled : false;
}

/**
 * Filter out disabled items, items with no active children or hidden in menu
 */
function filterChildren(items: ConsoleProduct[]) {
  if (!items) {
    return [];
  }
  return items.reduce<ConsoleProduct[]>((acc, e) => {
    if (isProductEnabledForEnv(e) && !e.hideInMenu) {
      if (e.children) {
        const children = e.children?.filter(c => isProductEnabledForEnv(c)) ?? [];

        if (children.length > 0) {
          return [
            ...acc,
            {
              ...e,
              children,
            },
          ];
        }
      } else {
        return [...acc, e];
      }
    }

    return acc;
  }, []);
}

const definition = ref<ProductsDefinition | null>(null);
const currentProductId = ref<string>('');

/**
 * Get helper items for menu (all services, releases etc.)
 */
const availableHelperItems = computed(() => {
  return definition.value ? filterChildren(definition.value?.helperItems) : [];
});

/**
 * Get product items for menu
 */
const availableProducts = computed(() => {
  return definition.value ? filterChildren(definition.value?.products) : [];
});

/**
 * Get secondary items for menu (documentation)
 */
const availableSecondaryItems = computed(() => {
  return definition.value?.additionalItems.reduce<ProductsDefinition['additionalItems']>((acc, g) => {
    const items = filterChildren(g);

    if (items.length > 0) {
      return [...acc, items];
    }
    return acc;
  }, []);
});

/**
 * Get flat map of all items
 */
const allItems = computed(() => {
  if (definition.value) {
    const prods = Object.values([...definition.value.helperItems, ...definition.value.products]).flatMap(e => {
      const children = (e.children ?? []).map(c => {
        return {
          ...c,
          parent: e.id,
        };
      });
      return [e, ...children];
    });

    definition.value.additionalItems.forEach(g => {
      prods.push(
        ...g.flatMap(e => {
          const children = (e.children ?? []).map(c => {
            return {
              ...c,
              parent: e.id,
            };
          });

          return [e, ...children];
        })
      );
    });

    return prods;
  }

  return [];
});

const getProduct = (id: string) => {
  return allItems.value.find(e => e.id === id);
};

const isProductEnabled = (id: string) => {
  const p = getProduct(id);

  if (p) {
    // special prod-only flag until configs are implemented
    return isProductEnabledForEnv(p);
  }

  return false;
};

/**
 * Only product items can be shown on homepage.
 */
const homepageItems = computed(() => {
  if (!definition.value) {
    return [];
  }

  const result: (ConsoleProduct & {
    category: string;
  })[] = [];

  definition.value.products.forEach(p => {
    if (isProductEnabled(p.id) && p.showOnHomepage) {
      result.push({
        ...p,
        description: p.summary || p.description,
        category: p.text,
      });
    }
    if (p.children) {
      p.children.forEach(c => {
        if (isProductEnabled(p.id) && c.showOnHomepage && isProductEnabled(c.id)) {
          result.push({
            ...c,
            description: c.summary || c.description,
            category: p.text,
          });
        }
      });
    }
  });

  return result;
});

async function loadProductsDefinition(force = false) {
  if (definition.value === null || force) {
    const { isConsoleTeamMember, isBetaUser, isGenAITeamMember } = useUser();

    if (CONSOLE_ENV !== 'prd' || isConsoleTeamMember() || isBetaUser() || isGenAITeamMember()) {
      console.log('%cYou are using a dev version of the products', 'font-size:20px', menuConfigDev);
      definition.value = menuConfigDev;
    } else {
      definition.value = menuConfig;
    }
  }

  return definition.value;
}

async function loadProductReleases(product: string) {
  const response = await ReleasesAPI.GET<DataTableResponse<ReleaseNote>>('/articles', { product: [product] });

  if (response?.data?.length > 0) {
    return {
      id: response.data[0].id,
      created_at: response.data[0].created_at,
      title: response.data[0].title,
      to: {
        serviceRouteName: 'releases' as ConsoleApp,
        route: {
          name: 'releases.release-details',
          params: {
            id: response.data[0].id.toString(),
          },
        },
      },
    } as ReleaseNote;
  }

  return null;
}

/**
 * Load pending requests count. We will show the number of requests to review in the menu
 * @returns {Number}
 */
async function loadNotificationsCount() {
  const [err, resp] = await handleAsync(
    CofferAPI.GET<DataTableResponse<Request>>('/notification-centre/requests-to-approve', {
      state: 'pending',
      limit: 1,
      resource_type: useLDConfig<string>('notifications-requests-resource-types')?.split(','),
    })
  );
  if (!err && resp) {
    return resp.meta.total;
  }
  return null;
}

const currentProduct = computed(() => getProduct(currentProductId.value));

export function useProducts() {
  return {
    currentProduct,
    currentProductId,
    definition,
    availableHelperItems,
    availableProducts,
    availableSecondaryItems,
    homepageItems,
    allItems,
    getProduct,
    isProductEnabled,
    loadProductsDefinition,
    loadProductReleases,
    loadNotificationsCount,
  };
}

import { parseProps } from '@console/router/utils.ts';
import type { ConsoleRouteDefinition } from '../types';

export default [
  {
    name: 'dapi.root',
    path: '/',
    redirect: { name: 'dapi.list-new' },
    // redirect: () => getLandingRoute('dapi.hasVisitedRoot', { name: 'dapi.about' }, { name: 'dapi.list' }),
  },
  {
    name: 'dapi.list',
    path: '/list',
    meta: {
      ninja: {
        name: 'dapi_list',
      },
    },
  },
  {
    name: 'dapi.list-new',
    path: '/list-new/:cuid?',
    props: parseProps(['cuid']),
    meta: {
      ninja: {
        name: 'dapi_list',
      },
    },
  },
  {
    name: 'dapi.edit',
    path: '/project/:projectName/edit',
    props: parseProps([]),
    meta: {
      title: 'DAPI project edit',
      ninja: {
        name: 'edit_dapi_project',
        props: to => ({ resource_id: to.params.projectName }),
      },
    },
  },
] as ConsoleRouteDefinition[];

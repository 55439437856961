declare global {
  interface Window {
    APP_CONFIG: Record<string, any>;
  }
}

export const IS_DEV = process.env.NODE_ENV !== 'production';

// read global APP_CONFIG object, set in ~/public/index.html
export const CONSOLE_ENV = window.APP_CONFIG?.CONSOLE_ENV as 'prd' | 'stg' | 'dev';
export const LOGIN_URL: string = window.APP_CONFIG?.LOGIN_URL;
export const LAQUESIS_API_URL: string = window.APP_CONFIG?.LAQUESIS_API_URL;
export const CATALOG_API_URL: string = window.APP_CONFIG?.CATALOG_API_URL;
export const COFFER_API_URL: string = window.APP_CONFIG?.COFFER_API_URL;
export const FREJAML_API_URL: string = window.APP_CONFIG?.FREJAML_API_URL;
export const RELEASES_API_URL: string = window.APP_CONFIG?.RELEASES_API_URL;
export const LAZARUS_API_URL: string = window.APP_CONFIG?.LAZARUS_API_URL;
export const GLOBAL_SEARCH_API_URL: string = window.APP_CONFIG?.GLOBAL_SEARCH_API_URL;
export const GENAI_API_URL: string = window.APP_CONFIG?.GENAI_API_URL;
export const DAPI_MANAGEMENT_API: string = window.APP_CONFIG?.DAPI_MANAGEMENT_API_URL;

export const NINJA_LOCATION: string = window.APP_CONFIG?.NINJA_LOCATION;
export const NINJA_ENV: string = window.APP_CONFIG?.NINJA_ENV;
export const NINJA_SITE_URL: string = window.APP_CONFIG?.NINJA_SITE_URL;

export const USERS_S3_BUCKET: string = window.APP_CONFIG?.USERS_S3_BUCKET;
export const JIRA_BASE_URI: string = window.APP_CONFIG?.JIRA_BASE_URI;
export const DOCS_URI: string = window.APP_CONFIG?.DOCS_URI;
export const SERVICE_WORKER_ENABLED: boolean = !IS_DEV && 'serviceWorker' in window.navigator;
export const PUBLIC_PATH: string = window.APP_CONFIG?.PUBLIC_PATH;

export const SENTRY_DSN: string = window.APP_CONFIG?.SENTRY_DSN;
export const LD_SDK_KEY: string = window.APP_CONFIG?.LD_SDK_KEY;
export const LD_CLIENT_SIDE_ID: string = window.APP_CONFIG?.LD_CLIENT_SIDE_ID;

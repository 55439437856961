<template>
  <div class="d-flex align-center">
    <v-menu location="bottom left" v-if="userActions.length > 0">
      <template #activator="{ props, isActive }">
        <v-badge :model-value="hasPendingAction" bordered location="top right" color="primary" dot offset-x="20" offset-y="15">
          <v-btn variant="text" icon class="text-white" v-bind="props">
            <v-icon>{{ profileIcon }}</v-icon>
            <v-icon size="small">{{ `mdi-chevron-${isActive ? 'up' : 'down'}` }}</v-icon>
          </v-btn>
        </v-badge>
      </template>

      <v-list density="comfortable" class="bg-surface2 text-on-surface">
        <v-list-subheader>{{ impersonatedUser ? `Impersonating ${impersonatedUser}` : userInfo.email }}</v-list-subheader>
        <v-divider class="mb-4" />

        <v-list-item link prepend-icon="mdi-content-copy" @click="onCopyToken">
          <v-list-item-title class="subheading">Copy access token</v-list-item-title>
        </v-list-item>

        <template :key="action.title" v-for="action in userActions">
          <v-list-item exact :href="action.href || ''" :to="action.to || ''" @click="action.click && action.click()">
            <template #prepend v-if="action.icon">
              <v-badge :model-value="action.badge || false" bordered location="top right" color="primary" dot>
                <v-icon>{{ action.icon }}</v-icon>
              </v-badge>
            </template>

            <v-list-item-title class="subheading">
              <div v-html="action.title" />
            </v-list-item-title>
          </v-list-item>
        </template>

        <v-divider />
        <v-list-item prepend-icon="mdi-git">
          <v-list-item-title class="subheading"> Version: {{ gitVersion }} </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <ImpersonateModal ref="impersonateModal" />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { RouteLocationRaw } from 'vue-router';
import { useRouter } from 'vue-router';
import { useTheme } from 'vuetify';
import { useModal, useSnackbar } from '@console/composables';
import { useUser } from '@console/composables/useUser';
import { trackEvent } from '@console/core/ninja';
import { getAppRouteHref } from '@console/router/routeUtils';
import { useThemes } from '@console/theme/useThemes';
import { decodeJWT } from '@console/utils';
import { useClipboard } from '@vueuse/core';
import { ImpersonateModal } from '../ImpersonateModal';

export default defineComponent({
  name: 'UserMenu',

  components: {
    ImpersonateModal,
  },

  setup() {
    const theme = useTheme();
    const router = useRouter();
    const { openModal } = useModal();
    const { copy } = useClipboard();
    const { openSnack } = useSnackbar();
    const { changeVariation, currentThemeVariation } = useThemes();
    const { impersonatedUser, token, userInfo, logout, impersonateUser, hasPendingAction } = useUser();
    const impersonateModal = ref(null);

    const tag = __GIT_TAG__;
    const hash = __GIT_HASH__;

    const gitVersion = ref(tag ? `${tag}@${hash}` : hash);

    const onLogoutClick = () => {
      logout();
      router.push('/login');
    };

    const onUserProfileClick = () => {
      if (router.currentRoute.value.name !== 'root.user-profile') {
        trackEvent('profile_page_click');

        if (typeof router.currentRoute.value.name === 'string' && router.currentRoute.value.name.includes('root')) {
          router.push({ name: 'root.user-profile' });
        } else {
          window.location.href = getAppRouteHref('-root-', { name: 'root.user-profile' });
        }
      }
    };

    const onImpClick = () => {
      if (impersonatedUser.value) {
        impersonateUser('');
        router.go(0);
      } else {
        openModal(impersonateModal.value, {
          email: '',
          onSave(email) {
            impersonateUser(email);
            router.go(0);
          },
        });
      }
    };
    const onCopyToken = () => {
      copy(token.value);
      openSnack({
        content: 'Token copied!',
      });
    };

    const userActions = computed<
      {
        enabled: () => boolean;
        title: string;
        icon: string;
        click?: () => void;
        to?: RouteLocationRaw;
        href?: string;
        badge?: boolean;
      }[]
    >(() => {
      // const themeSwitches = Object.keys(themes).map(name => {
      //   return {
      //     enabled: () => true,
      //     title: `Use <span class="${getThemeClass(name as any)}"><b class="primary--text text-uppercase">${name}</b></span> palette`,
      //     icon: 'mdi-palette',
      //     click: () => changeTheme(name as any),
      //   };
      // });

      const actions = [
        {
          enabled: () => !!impersonatedUser.value,
          title: `Reset impersonating`,
          icon: 'mdi-ghost-off',
          click: () => onImpClick(),
        },
        {
          enabled: () => userInfo.value?.authinfo.can_impersonate && !impersonatedUser.value,
          title: 'Impersonate a user',
          icon: 'mdi-ghost',
          click: () => onImpClick(),
        },
        {
          enabled: () => true,
          title: 'User Profile',
          icon: 'mdi-account-circle',
          click: () => onUserProfileClick(),
          badge: hasPendingAction.value,
        },
        {
          enabled: () => true,
          title: currentThemeVariation.value === 'dark' ? 'Use light theme' : 'Use dark theme',
          icon: 'mdi-theme-light-dark',
          click: () => {
            changeVariation(theme);
          },
        },
        // ...themeSwitches,
        {
          enabled: () => true,
          title: 'Logout',
          icon: 'mdi-logout',
          click: () => onLogoutClick(),
        },
      ];

      return actions.filter(a => a.enabled());
    });
    const username = computed(() => {
      const data = decodeJWT(token.value);

      return `${data['custom:first_name']} ${data['custom:last_name']}`;
    });
    const profileIcon = computed(() => {
      if (impersonatedUser.value) {
        return 'mdi-ghost';
      }

      return 'mdi-account-circle';
    });

    return {
      gitVersion,
      userInfo,
      impersonateModal,
      userActions,
      username,
      profileIcon,
      impersonatedUser,
      onCopyToken,
      hasPendingAction,
    };
  },
});
</script>

import {
  CATALOG_API_URL,
  COFFER_API_URL,
  DAPI_MANAGEMENT_API,
  FREJAML_API_URL,
  GENAI_API_URL,
  GLOBAL_SEARCH_API_URL,
  LAQUESIS_API_URL,
  LAZARUS_API_URL,
  RELEASES_API_URL,
} from '@console/constants/config';
import HttpService from '@console/core/http/HttpService';

export { default as HttpService } from './HttpService';

export const LocalAPI = new HttpService('/');
export const LaquesisAPI = new HttpService(LAQUESIS_API_URL);
export const LazarusAPI = new HttpService(LAZARUS_API_URL);
export const CatalogAPI = new HttpService(CATALOG_API_URL);
export const FrejamlAPI = new HttpService(FREJAML_API_URL);
export const ReleasesAPI = new HttpService(RELEASES_API_URL);
export const CofferAPI = new HttpService(COFFER_API_URL);
export const GlobalSearchAPI = new HttpService(GLOBAL_SEARCH_API_URL);
export const GenAIAPI = new HttpService(GENAI_API_URL);
export const DapiManagementAPI = new HttpService(DAPI_MANAGEMENT_API);
